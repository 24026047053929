
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { DateTime } from 'luxon';
import { getElementBySkuOrId, calculateOptionQuantityThatCanBeAdded } from '@/utils/helpers';
import { formatTemporarySelectedOrderOption, setTemporaryOption } from '@/utils/format';
import { fireGoogleTag } from '@/utils/google-tag-manager-helpers';

const namespace = 'cart';

@Component<Receipt>({})
export default class Receipt extends Vue {
	@Action('setTemporaryItem', { namespace }) private setTemporaryItem!: (temporaryItemPayload: { tempItem: MenuItem|{}, quantity?: number }) => void;
	@Action('addToCart', { namespace }) private addToCart!: (extraData: any) => void;
	@Action('updateOrderOptions', { namespace }) private updateOrderOptions!: (options: any) => void;
	@Action('updateTemporaryItemPrice', { namespace }) private updateTemporaryItemPrice!: (prices: Prices) => void;
	@Getter('getItems', { namespace }) private getCartItems!: OrderItem[];
	@Getter('getMaxItemsPerCart', { namespace: 'restaurant' }) private maxItemsPerCart!: number;
	@Getter('getRestaurant', { namespace: 'restaurant' }) private restaurant!: Restaurant;
	@Getter('getFilteredMenus', { namespace: 'restaurant' }) private filteredMenus!: Menu[];
	@Prop({ type: String, required: true, default: '' }) private id!: string;
	@Prop({ type: Boolean, required: false, default: false }) private isAnyLoading!: boolean;
	@Prop({ type: Object, required: true, default: () => {} }) private receipt!: ReceiptInfo;

	private showDetails: boolean = false;
	private isLoading: boolean = false;

	private receiptAmount: number =
		(this.receipt.subtotal || 0)
		+ (this.receipt.serviceCharge || 0)
		+ (this.receipt.preTaxDiscount || 0)
		+ (this.receipt.posPreTaxDiscount || 0)
		+ (this.receipt.tax || 0)
		+ (this.receipt.deliveryCharge || 0)
		+ (this.receipt.payment || 0);

	private get isCurrentLocation(): boolean {
		if (this.restaurant?.app8_restaurant && this.receipt.restaurantId && this.restaurant?.app8_restaurant === this.receipt.restaurantId ) {
			return true;
		}
		return false;
	}

	/**
	 * Format the date of the order's receipt
	 *
	 * @param {string} utcDate
	 * @return {string}
	 */
	private formatDate(utcDate: string): string {
		const date = DateTime.fromISO(utcDate).toFormat('D');
		const time = DateTime.fromISO(utcDate).toFormat('t');
		return `${date} • ${time}`;
	}

	/**
	 * Format negative numbers
	 *
	 * @param {number} amount
	 * @return {string}
	 */
	private formatNegativeAmount(amount: number): string {
		return Math.abs(amount).toFixed(2);
	}

	/**
	 * Show or hide receipt details
	 * and scroll to the position of that receipt
	 *
	 * @return {void}
	 */
	private toggleShowReceipt(): void {
		this.showDetails = !this.showDetails;
		if (this.showDetails) {
			setTimeout(() => {
				const receiptElement = document.getElementById('receipt-' + this.id);
				receiptElement && receiptElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
			}, 100);
		}
	}

	/**
	 * Loop through the sections items and menu items of each menu to find the item with the matching SKU.
	 *
	 * @param {string} sku
	 * @return {MenuItem | undefined}
	 */
	private getItemFromMenu(sku: string): MenuItem | undefined {
		let menuItem;
		for (const menu of this.filteredMenus) {
			// Section item
			for (const section of menu.sections) {
				menuItem = section.items.find((item) => getElementBySkuOrId(item, sku as string));
				if (menuItem) {
					return menuItem;
				}
			};

			// Menu item
			menuItem = menu?.items.find((item) => getElementBySkuOrId(item, sku as string));
			if (menuItem) {
				return menuItem;
			}
		}
		return menuItem;
	}

	/**
	 * Add the items from the receipt to the cart if possible.
	 *
	 * @return {Promise<void>}
	 */
	private async reorderItems(): Promise<void> {
		// If cart is already full, stop here
		const itemsInCartBefore: number = this.getCartItems.reduce((sum, item) => sum += item.quantity, 0);
		let itemsInCart: number = itemsInCartBefore;
		if (itemsInCart === this.maxItemsPerCart) {
			this.$toasted.show(this.$t('profile.past_receipts.error_cart_full'), { type: 'error', position: 'top-center' }).goAway(5000);
			return;
		}

		this.isLoading = true;
		this.$emit('loading', true);

		let changeWarningFlag: boolean = false;
		// Loop through each receipt's items and find item in the section/menu
		for (const receiptItem of this.receipt.items) {
			if (!receiptItem.sku || receiptItem.quantity <= 0) {
				changeWarningFlag = true;
				continue;
			}
			const menuItem: MenuItem | undefined = this.getItemFromMenu(receiptItem.sku);
			if (!menuItem || menuItem.sold_out) {
				changeWarningFlag = true;
				continue;
			}
			const itemQuantity: number = this.calculateItemQuantityThatCanBeAdded(receiptItem.quantity, itemsInCart);
			itemsInCart += itemQuantity;
			if (itemQuantity < receiptItem.quantity) {
				changeWarningFlag = true;
			}

			this.setTemporaryItem({ tempItem: menuItem, quantity: itemQuantity });

			let skipItemFlag: boolean = false;

			// Modifiers
			if (menuItem.options) {
				const optionsResult = this.setOptions(menuItem.options, receiptItem.items);
				const options: OrderOptionGroup[]= optionsResult.options;
				skipItemFlag = optionsResult.skip || skipItemFlag;
				changeWarningFlag = optionsResult.warning || changeWarningFlag;

				// Skip the item if a required option is missing and remove its quantity from the cart
				if (skipItemFlag) {
					itemsInCart -= itemQuantity;
					continue;
				}

				// Check if we were able to find all the options from the receipt or not before updating the order options
				if (options.length) {
					if (!changeWarningFlag) {
						changeWarningFlag = !this.checkIfAllOptionsAdded(options, receiptItem.items);
					}
					this.updateOrderOptions(options);
				}
			}

			// Add item to cart
			const extraData = {
				special_instructions: receiptItem.items.find((option: ReceiptSubItem) => option.memo)?.name || '',
				originalPrice: menuItem.price,
				type: 'reorder'
			};
			try {
				await this.addToCart(extraData);
			}
			// Skip the item if error from server (menu unavailable, etc.)
			catch (error) {
				changeWarningFlag = true;
				itemsInCart -= itemQuantity;
				continue;
			}

			// Stop if we reach the cart limit
			if (itemsInCart === this.maxItemsPerCart) {
				break;
			}
		}

		setTimeout(() => {
			this.isLoading = false;
			this.setTemporaryItem({ tempItem: {}});
			this.$emit('loading', false);
		}, 500)

		// Check if we actually added items
		const itemsInCartAfter: number = this.getCartItems.reduce((sum, item) => sum += item.quantity, 0);
		const itemsAddedToCart: number = itemsInCartAfter - itemsInCartBefore;
		const itemsInReceipt: number = this.receipt.items.reduce((sum, item) => sum += item.quantity, 0);
		const itemsNotAddedToCart: number = itemsInReceipt - itemsAddedToCart;

		fireGoogleTag({
			name: 'reorderCart',
			detail: `${itemsAddedToCart} succeed`,
			specifier: `${itemsNotAddedToCart} attempt`
		});
		if (itemsAddedToCart === 0) {
			this.$toasted.show(this.$t('profile.past_receipts.error_reorder_items'), { type: 'error', position: 'top-center' }).goAway(5000);
			return;
		}

		this.redirectToMenu(changeWarningFlag);
	}

	/**
	 * Loop through the option groups of an item to set the options of a receipt item.
	 * Raise a warning if changes were made to the option.
	 * Raise a skip flag if a required option is missing.
	 *
	 * @param {OptionGroup[]} itemOptions
	 * @param {ReceiptSubItem[]} receitItemOptions
	 * @return {{ options: OrderOptionGroup[]; warning: boolean; skip: boolean; }}
	 */
	private setOptions(itemOptions: OptionGroup[], receitItemOptions: ReceiptSubItem[]): { options: OrderOptionGroup[]; warning: boolean; skip: boolean; } {
		const options: OrderOptionGroup[]= [];
		let warningFlag: boolean = false;
		let skipFlag: boolean = false;
		for (const optionGroup of itemOptions) {
			if (optionGroup.values?.length) {
				let selectedOptionsCounter: number = 0;
				let tempOptionGroup: OrderOptionGroup | undefined = undefined;
				for (const option of optionGroup.values) {
					const receiptItemOption: ReceiptSubItem | undefined = receitItemOptions.find(({ sku }: ReceiptSubItem) => getElementBySkuOrId(option, sku));
					if (receiptItemOption) {
						// Calculate the quantity of the option
						if (receiptItemOption.quantity <= 0) {
							continue;
						}
						if (receiptItemOption.quantity > 1) {
							const quantity: number = calculateOptionQuantityThatCanBeAdded(optionGroup, option, receiptItemOption.quantity, selectedOptionsCounter);
							if (quantity < receiptItemOption.quantity) {
								warningFlag = true;
							}
							selectedOptionsCounter += quantity;
							option.quantity = quantity;
						}
						else {
							selectedOptionsCounter++;
						}

						// Set option group and its option if it's the first loop and tempOptionGroup is still undefined
						if (!tempOptionGroup) {
							tempOptionGroup = setTemporaryOption(optionGroup, option);
						}

						// Pricing (we don't have to check for undefined values because pricing always have 1 value)
						if (optionGroup.type === 'pricing' && +tempOptionGroup!.values![0].price! > 0) {
							this.updateTemporaryItemPrice({ price: tempOptionGroup!.values![0].price!.toString(), memberPrice: tempOptionGroup!.values![0].memberPrice });
						}

						// Add-ons
						else {
							// Multi-selection
							if (optionGroup.allow_multiple_selection && tempOptionGroup.values?.[0].id !== option.id) {
								// Add the option value to the other ones if possible
								tempOptionGroup.values!.push(formatTemporarySelectedOrderOption(option));
							}
							// Sub-modifiers
							if (option.options?.length) {
								const subOptionsResult = this.setSubOptions(option, receiptItemOption);
								skipFlag = subOptionsResult?.skip || skipFlag;
								warningFlag = subOptionsResult?.warning || warningFlag;
								if (skipFlag) {
									break;
								}
								const optionIndex = tempOptionGroup.values?.findIndex(({ id }: OrderOption) => id === option.id) as number;
								if (tempOptionGroup.values) {
									tempOptionGroup.values[optionIndex].options = subOptionsResult?.subOptions || [];
								}
							}
							// Break if single selection now that we added its sub-options
							if (!optionGroup.allow_multiple_selection) {
								break;
							}
							// Make sure we don't select more options than the limit allows
							if (optionGroup.allow_multiple_selection && typeof optionGroup.limit === 'number' && optionGroup.limit > 0 && selectedOptionsCounter >= optionGroup.limit) {
								break;
							}
						}
					}
				}

				if (skipFlag) {
					break;
				}

				// If a required option is missing, don't add the item to the cart
				if ((!tempOptionGroup || !tempOptionGroup.values || !tempOptionGroup.values.length) && optionGroup.required) {
					return { options: [], warning: true, skip: true };
				}

				// Check if the option group has default options, add them if they're not selected (quantity of 0)
				tempOptionGroup = this.addNonSelectedDefaultOptions(optionGroup, tempOptionGroup);

				// Add the option group and update the item's order options
				if (tempOptionGroup?.values?.length) {
					options.push(tempOptionGroup);
				}
			}
		}
		return { options, warning: warningFlag, skip: skipFlag}
	}

	/**
	 * Go through the sub-options of an item's option to add/select the ones from the receipt if possible.
	 * Raise a warning if changes were made to the sub-options.
	 * Raise a skip flag if a required sub-option is missing.
	 *
	 * @param {Option} option
	 * @param {ReceiptSubItem} receiptItemOption
	 * @return {{ subOptions: OrderOptionGroup[]; warning: boolean; skip: boolean; } | undefined}
	 */
	private setSubOptions(option: Option, receiptItemOption: ReceiptSubItem): { subOptions: OrderOptionGroup[]; warning: boolean; skip: boolean; } | undefined {
		if (option.options?.length) {
			let warningFlag: boolean = false;
			const subOptions: OrderOptionGroup[] = [];
			for (const subOptionGroup of option.options) {
				if (subOptionGroup.values?.length) {
					let tempSubOptionGroup: OrderOptionGroup | undefined = undefined;
					let selectedOptionsCounter: number = 0;
					for (const subOption of subOptionGroup.values) {
						// Check if the sub-option value was in the receipt item
						const receiptItemSubOption = receiptItemOption.items.find(({ sku }: ReceiptSubItem) => getElementBySkuOrId(subOption, sku));
						if (receiptItemSubOption) {
							// Multiple sub-options selection
							if (subOptionGroup.allow_multiple_selection) {
								// Make sure we don't select more subOptions than the limit allows
								if (typeof subOptionGroup.limit === 'number' && subOptionGroup.limit > 0 && selectedOptionsCounter >= subOptionGroup.limit) {
									break;
								}
								// Add the sub-option value to the other ones if possible
								if (tempSubOptionGroup?.values?.length) {
									tempSubOptionGroup.values.push(formatTemporarySelectedOrderOption(subOption));
								}
								// Set the sub-option group with that sub-option value
								else {
									tempSubOptionGroup = setTemporaryOption(subOptionGroup, subOption);
								}
								selectedOptionsCounter++;
							}
							// Single selection
							else {
								tempSubOptionGroup = setTemporaryOption(subOptionGroup, subOption);
								break
							}
						}
					}

					// Raise skip item flag if missing required sub-option
					if ((!tempSubOptionGroup || !tempSubOptionGroup.values || !tempSubOptionGroup.values.length) && subOptionGroup.required) {
						return { subOptions: [], warning: true, skip: true };
					}

					tempSubOptionGroup = this.addNonSelectedDefaultOptions(subOptionGroup, tempSubOptionGroup);

					// Add the sub-optionGroup to the array
					if (tempSubOptionGroup) {
						subOptions.push(tempSubOptionGroup)
					}
				}
			}
			// Check if we were able to find all the options from the receipt or not before updating the order options
			if (subOptions.length) {
				const optionGroupValueTotal: number = subOptions.reduce((sum, optGroup) => sum + (optGroup.values?.length || 0), 0);
				const optionsCountInReceiptItems: number = receiptItemOption.items.length;
				warningFlag = optionsCountInReceiptItems !== optionGroupValueTotal;
			}
			return { subOptions, warning: warningFlag, skip: false };
		}
	}

	/**
	 * Calculate quantity of the item we can add, depending of the cart limit and current items in cart
	 *
	 * @param {number} receiptItemQuantity
	 * @param {number} numberOfItemsInCart
	 * @return {number}
	 */
	private calculateItemQuantityThatCanBeAdded(receiptItemQuantity: number, numberOfItemsInCart: number): number {
		let itemQuantity: number = receiptItemQuantity;
		if (this.maxItemsPerCart > 0) {
			if (itemQuantity > this.maxItemsPerCart) {
				itemQuantity = this.maxItemsPerCart;
			}
			if (itemQuantity + numberOfItemsInCart > this.maxItemsPerCart) {
				itemQuantity = this.maxItemsPerCart - numberOfItemsInCart;
			}
		}
		return itemQuantity;
	}

	/**
	 * Add the non selected default option to the group
	 *
	 * @param {OptionGroup} optionGroup - The original option group to check if there are default selection options
	 * @param {OrderOptionGroup|undefined} tempOptionGroup - The order option group being built, can be undefined if no option was selected
	 * @return {OrderOptionGroup|undefined} Can be undefined if no option was selected, and the option group has no default selection
	 */
	private addNonSelectedDefaultOptions(optionGroup: OptionGroup, tempOptionGroup: OrderOptionGroup|undefined): OrderOptionGroup | undefined {
		let tempOptionGroupCopy: OrderOptionGroup|undefined = structuredClone(tempOptionGroup);
		let defaultSelections: Option[] = optionGroup.values!.filter((subOptionValue: Option) => subOptionValue.default_selection);

		if (defaultSelections.length) {
			defaultSelections.forEach((defaultOption: Option) => {
				if (tempOptionGroupCopy) {
					if (!tempOptionGroupCopy.values!.some((optionValue: OrderOption) => optionValue.id === defaultOption.id)) {
						tempOptionGroupCopy.values!.push(formatTemporarySelectedOrderOption({ ...defaultOption, quantity: 0 }));
					}
				}
				else {
					tempOptionGroupCopy = setTemporaryOption(optionGroup, { ...defaultOption, quantity: 0 });
				}
			})
		}
		return tempOptionGroupCopy;
	}

	/**
	 * Check if all the options from the receipt item were found or not.
	 * Filter out the unselected default options and compare the length of items.
	 *
	 * @param {OrderOptionGroup[]} options
	 * @param {ReceiptSubItem[]} receiptItemSubItems
	 * @return {boolean}
	 */
	private checkIfAllOptionsAdded(options: OrderOptionGroup[], receiptItemSubItems: ReceiptSubItem[]): boolean {
		const optionGroupValueTotal: number = options.reduce((sum, optGroup) => {
			const validValues = optGroup.values?.filter(value => value.quantity && value.quantity >= 0) ?? [];
			return sum + validValues.length;
		}, 0)

		const optionsCountInReceiptItems: number = receiptItemSubItems.filter((subItem: ReceiptSubItem) => !subItem.memo && subItem.quantity > 0).length;
		return optionsCountInReceiptItems === optionGroupValueTotal;
	}

	/**
	 * Redirect to the menu with the cart open, and a warning modal about the changes if applicable
	 *
	 * @param {boolean} changeWarning
	 * @return {void}
	 */
	private redirectToMenu(changeWarning: boolean): void {
		const queryParams: { [key: string]: any } = { ...this.$route.query, openCart: 'true' };
		if (changeWarning) {
			queryParams.reorderWarning = 'true';
		}
		this.$router.push({ path: `/${this.restaurant?.slug}`, query: queryParams }).catch(() => {});
	}
}
